import React from 'react'
import HeadSection from '../components/HeadSection'
import LogicSection from '../components/LogicSection'
import PackageSection from '../components/PackageSection'
import ChatIcon from '../components/ChatIcon'

import '../styles/CheckoutPage.css'
const CheckoutPage = () => {
  return (
    <div id='checkout-page'>
      <HeadSection/>
      <LogicSection/>
      <PackageSection/>
      <ChatIcon/>
    </div>
  )
}

export default CheckoutPage