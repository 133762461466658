import React,{useState, useEffect} from 'react';
import API from '../ultis/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LogicSection = () => {
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [output, setOutput] = useState('Output code will be displayed here!');
    const [remainTurn, setRemainTurn] = useState(0);
    const [remainTime, setRemainTime] = useState("0:0:0");
    const [showInvalidCodeButtons, setShowInvalidCodeButtons] = useState(false);
    
    const scrollToId = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }}

        const runLogic = async () => {
            scrollToId("code-output");
            try {
                let input_payload = JSON.stringify({
                    "input1": input1,
                    "input2": input2
                });
                let resp = await API.post("coinbase/execute_logic", input_payload);
                let resp_data = resp.data;
        
                if (resp.status === 200) {
                    setOutput(resp_data.output);
                    setRemainTurn(resp_data.remain_turn);
                    toast.success("Your code has executed successfully!", {
                        position: "top-right"
                    });
                    setShowInvalidCodeButtons(false); // Ẩn các nút nếu thành công
                } else if (resp.status === 403 && resp_data.message === "MERGED CODE IS INVALID") {
                    // Logic xử lý khi mã không hợp lệ
                    toast.error("Merging these two indicators failed; please merge manually or try other indicators", {
                        position: "top-right"
                    });
                    setShowInvalidCodeButtons(true); // Hiển thị các nút khi mã không hợp lệ
                    console.log("Merged code is invalid. Handling error.");
                } else {
                    toast.warn(`Unexpected status: ${resp.status}`, {
                        position: "top-right"
                    });
                }
        
                console.log(resp);
            } catch (error) {
                // Kiểm tra xem lỗi có phải là 403 không
                if (error.response && error.response.status === 403) {
                    let resp_data = error.response.data;
        
                    // Kiểm tra thông điệp lỗi
                    if (resp_data.message === "MERGED CODE IS INVALID") {
                        toast.error("Merging these two indicators failed; please merge manually or try other indicators", {
                            position: "top-right"
                        });
                        setShowInvalidCodeButtons(true); // Hiển thị các nút khi mã không hợp lệ
                        console.log("Your merged code invalid, you can use or not");
                    } else {
                        // Xử lý các lỗi 403 khác
                        toast.error(`Merging these two indicators failed; please merge manually or try other indicators`, {
                            position: "top-right"
                        });
                    }
                } else {
                    // Xử lý các lỗi khác (không phải 403)
                    console.log(error);
                    toast.error("Merging these two indicators failed; please merge manually or try other indicators", {
                        position: "top-right"
                    });
                }
            }
        };
        
    const handleInputChange = (e,setInput)=>{
        setInput(e.target.value)
    }

    const handleReport = () => {
        const confirmRedirect = window.confirm("Do you want to go to the Discord server to report?");
        if (confirmRedirect) {
            window.location.href = 'https://discord.com/invite/XyPuKHUrEz';
        }
    }
    
    const handleDownload = () => {
        const outputText = document.querySelector('.output > pre')?.innerText;

        if (!outputText) {
            // If there's no text to download, show an error toast
            toast.error("Unable to download: No content found");
            return; // Exit the function early
        }

        try {
            const blob = new Blob([outputText], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            
            const a = document.createElement('a');
            a.href = url;
            a.download = 'output.txt'; // Filename for the download
            document.body.appendChild(a); // Required for FireFox
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            // If everything goes well, show a success toast
            toast.success("Download started successfully");
        } catch (error) {
            // If any error occurs during the process, show an error toast
            toast.error("Unable to start download");
        }
    };

    const handleWantToUse = async () => {
        try {
            const response = await API.post("coinbase/get_invalid_code", { is_use: true });
            if (response.status === 200) {
                const { output, remain_turn } = response.data;
                setOutput(output);
                setRemainTurn(remain_turn);
                toast.success("Request was successful!", {
                    position: "top-right"
                });
                setShowInvalidCodeButtons(false); // Ẩn các nút sau khi thành công
            } else {
                toast.error("Request failed!", {
                    position: "top-right"
                });
            }
        } catch (error) {
            toast.error("Request failed!", {
                position: "top-right"
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await API.get("coinbase/user_budget");
                const resp_data = resp.data;
                setRemainTurn(resp_data.remain_turn);
                setRemainTime(resp_data.remain_time); // Giả sử remain_time từ API đã có định dạng ngày:giờ:phút
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchData();
    }, [remainTime]);

    useEffect(() => {
        const interval = setInterval(() => {
          // Tính toán thời gian còn lại
          const [days, hours, minutes] = (remainTime?.split(':') ?? ["0", "0", "0"]).map(Number);
          let totalMinutes = days * 24 * 60 + hours * 60 + minutes - 1; // Giảm 1 phút
    
          if (totalMinutes >= 0) {
            let newDays = Math.floor(totalMinutes / (24 * 60));
            totalMinutes %= 24 * 60;
            let newHours = Math.floor(totalMinutes / 60);
            let newMinutes = totalMinutes % 60;
            setRemainTime(`${newDays.toString().padStart(2, '0')}:${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`);
          } else {
            clearInterval(interval); // Dừng đếm ngược khi hết giờ
          }
        }, 60000); // Cập nhật mỗi phút
    
        return () => clearInterval(interval); // Dọn dẹp khi component bị unmount
      }, [remainTime]);

      return (
        <div className='logic-section'>
            <div className='input-container'>
                <textarea 
                    id='input1' 
                    placeholder='Input1'
                    value={input1}
                    onChange={(e) => handleInputChange(e, setInput1)}
                ></textarea>
                <textarea 
                    id='input2' 
                    placeholder='Input2'
                    value={input2}
                    onChange={(e) => handleInputChange(e, setInput2)}
                ></textarea>
                <div id='budget'>
                    <div id='turn-remain'>
                        <div className='info'>
                            <strong>{remainTurn}</strong> uses left
                        </div>
                    </div>
                    <div id='time-remain'>
                        <div className='info'>
                            <strong>Expired in:</strong>
                            <div>{remainTime}</div>
                        </div>
                    </div>
                    <div id='run-button' onClick={runLogic}>Run</div>
                </div>
            </div>
            <div className='output' id="code-output">
                <pre>{output}</pre>
            </div>
            {showInvalidCodeButtons && (
                <div className='invalid-code-buttons'>
                    <button onClick={handleWantToUse}>View Merged Script Anyway! (Will Use 1 Credit)</button>
                    <button onClick={() => setShowInvalidCodeButtons(false)}>Cancel</button>
                </div>
            )}
            <div className='button-container'>
                <div id='buy-more' onClick={() => scrollToId("package-section")}>Buy more</div>
                <div id='copy' onClick={handleReport}>Report</div>
                <div id='download' onClick={handleDownload}>Download</div>
            </div>
        </div>
    )
}

export default LogicSection