import React,{useState} from 'react';
import API from '../ultis/API';

const PackageSection = () => {
    const [activePackage, setActivePackage] = useState(1);
    const [selectedPackage, setSelectedPackage ] = useState({
        name:"Package 1",
        price:1
    });

    const handleChoosePackage = (name, price)=>{
        setSelectedPackage({
            name:name,
            price:price
        });
        setActivePackage(price);
        // console.log(selectedPackage)
    }

    const handleSubmitPay = async ()=>{
        try{
            let package_data = {
                "local_price":{
                    "amount":selectedPackage['price'],
                    "currency":"USD"
                },
                "pricing_type": "fixed_price",
                "name":selectedPackage['name'],
                "description":`Package $${selectedPackage['price']}`,
                "redirect_url":"https://code2trade.top/#/"
            }
            let resp = await API.post("coinbase/checkout",
            package_data);
            let coinbase_hosted_url = resp.data['hosted_url'];
            // window.open(coinbase_hosted_url, '_blank').focus();
            window.location.href = coinbase_hosted_url;
            console.log("CHECKOUT API >>>>>",resp.data)
        } catch(error){
            alert("Something wrong!!!")
        }
    }
    return (
        <div className='package-section' id="package-section">
            <div className='package-container'>
                <div className= {`package ${activePackage === 1 ? 'active' : ''}`} onClick={()=>handleChoosePackage('Package 1', 1)}>
                    <div className='package-name'>Pack $1</div>
                    <div className='package-info'>1 use expired in 24hrs</div>
                </div>
                <div className= {`package ${activePackage === 5 ? 'active' : ''}`} onClick={()=>handleChoosePackage('Package 2', 5)}>
                    <div className='package-name'>Pack $5</div>
                    <div className='package-info'>20 uses expired in 24hrs</div>
                </div>
                <div className= {`package ${activePackage === 500 ? 'active' : ''}`} onClick={()=>handleChoosePackage('Package 3', 500)}>
                    <div className='package-name'>Pack $500</div>
                    <div className='package-info'>1000 uses expired in 30 days</div>
                </div>
            </div>
            <div className='button-container'>
                <div className='pay button' onClick={()=>handleSubmitPay()}>Pay with coinbase (ETH or USDC on base)</div>
                <div className='guide button'>Guide</div>
            </div>
        </div>
    )
}

export default PackageSection