import React from 'react';
import discordIcon from '../image/discord_icon.png';
const ChatIcon = () => {
    const handleRedirect = () => {
        const confirmRedirect = window.confirm("Do you want to go to our Discord server?");
        if (confirmRedirect) {
            window.location.href = 'https://discord.gg/XyPuKHUrEz';
        }
    };

    return (
        <button className='discord-icon' onClick={handleRedirect}>
            <img src={discordIcon} alt="Discord Icon" />
        </button>
    );
};

export default ChatIcon;