import axios from 'axios';

const API = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_API}/api/`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    }
});

const get_token = () => {
    const tokenString = localStorage.getItem('authToken');
    return tokenString ? JSON.parse(tokenString) : null;
};

API.interceptors.request.use(
    async (config) => {
        const token = get_token();
        if (token && token.access) {
            config.headers['Authorization'] = `Bearer ${token.access}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("authUser");
            window.location.href = '/#/login';
        }
        return Promise.reject(error);
    }
);

export default API;