import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
const ProtectedRoute = () => {
  let { user } = useContext(AuthContext);
  
    if (user){
      console.log("Private route",user["policy_check"], typeof(user))
      if (user.policy_check){
        return <Outlet />;
      }
      else{
        return <Navigate to="/policy" />;
      }
    }
    else{
      return <Navigate to="/login" />;
    }
  }; // Add closing curly brace here

  export default ProtectedRoute;
