import "./App.css";
import Header from "./components/Header";
import CheckoutPage from './pages/CheckoutPage'
import LoginPage from "./pages/LoginPage";
import RegisterPage from './pages/RegisterPage'
import Policy from './pages/PolicyPage'

import ProtectedRoute from "./ultis/ProtectedRoute";
import { ContextProvider } from "./context/AuthContext";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <div className="container dark">
        <div className="app">
          <ContextProvider>
            <Header />
            <ToastContainer />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/policy" element={<Policy/>}/>
              
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<CheckoutPage/>} />
              </Route>
            </Routes>
          </ContextProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;