import React, { useState, useContext } from 'react';
import API from '../ultis/API';
import AuthContext from '../context/AuthContext'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/PolicyPage.css'
import { useNavigate } from 'react-router-dom';

const PolicyPage = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const { updateToken, authToken} = useContext(AuthContext)
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const handleContinue = async () => {
        try {
            let input_payload = JSON.stringify({
                "policy_check": isChecked
            });
            let resp = await API.patch("accounts/update/", input_payload);
            if (resp.status === 200) {
                await updateToken(authToken); // Ensure this updates the token with the new policy_check status
                toast.success("Welcome to our service!", {
                    position: "top-right"
                });
                navigate("/");
            } else {
                toast.error("Policy check failed!", {
                    position: "top-right"
                });
            }
        } catch (error) {
            console.log(error);
            toast.error("Policy check failed!", {
                position: "top-right"
            });
        }
    };
    
    return (
        <div id = "policy-page">
            <div class="disclaimer-policy">
                <h3>Disclaimer and Policy</h3>
                <p>The indicator combining function provided on this website is for informational and educational purposes only. The combined indicator is intended solely as a reference tool and does not constitute financial or investment advice.</p>
                <h3>Important Points to Note:</h3>
                <ol>
                    <li><strong>No Investment Advice:</strong> The information and indicators on this website are not to be considered as investment advice, recommendations, or endorsements of any financial instruments or trading strategies.</li>
                    <li><strong>User Responsibility:</strong> Users are solely responsible for any trading decisions they make based on the use of the combined indicator. The outcomes of such decisions, including any profits or losses, are the sole responsibility of the user.</li>
                    <li><strong>No Liability:</strong> This website and its creators shall not be held liable for any financial losses or damages that may occur as a result of using the combined indicator. By using the indicator, users acknowledge and accept that trading involves significant risk and that they use the indicator at their own risk.</li>
                    <li><strong>No Guarantees:</strong> The performance of the combined indicator is not guaranteed, and past performance does not predict future results. Users should conduct their own research and due diligence before making any trading decisions.</li>
                    <li><strong>Third-Party Platforms:</strong> The use of the combined indicator on any third-party broker or exchange platform is at the discretion of the user. This website has no affiliation with any such platforms and does not endorse or take responsibility for their services or the outcomes of using the indicator on these platforms.</li>
                </ol>
                <strong>This indicator is for reference purposes only, not investment advice, and is not related to any trade decisions on the user’s account or on any broker or exchange platform. By using this website and its indicator combining function, you acknowledge that you have read, understood, and agreed to this disclaimer and policy.</strong>
            </div>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                I agree to the policy
            </label>
            <br />
            
            <button className={`continue-btn ${isChecked?"active":"disabled"}`} disabled={!isChecked} onClick={handleContinue}>Continue</button>
        </div>
    );
};

export default PolicyPage;