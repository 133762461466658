import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import LoginImage from '../image/Login_asset.png'
import { Link } from "react-router-dom";
import '../styles/LoginPage.css'

const LoginPage = () => {
  let { loginUser } = useContext(AuthContext);
  return (
    <div className="app-login">
      <div id="login-form-container">
        <div className="login-header">
          <img src={LoginImage} alt="login" />
        </div>
        <form id="login-form" action="" onSubmit={loginUser}>
          <div className="input-field">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Enter UserName"
              className="input"
            />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter Password"
              className="input"
            />
          </div>
          <div className="login-button-container">
            <input type="submit" value="Login" id="login-button" />
            <div id="register">
            Don't have an account?{" "}
            <span id="register-link">
              <Link to={"/register/"}>Register</Link>
            </span>
          </div>
          </div>
          
        </form>
      </div>
    </div>
  );
};

export default LoginPage;