import React from 'react'

const HeadSection = () => {

  return (
    <div className='head-section'>
        <div className='image'>
          <iframe width="100%" height="auto" 
            src="https://www.youtube.com/embed/videoseries?si=ZhaPlCBH3COkxpFg&amp;list=PLQFji30_Cf6nhBhgaJKiXse1cWsUYoe4-"
            title="Tutorial video list" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen="true">
            </iframe>
        </div>
        <div className='info'>
            <div>Copy code of any indicator</div>
            <div>1. Click on Source Code -  the Brace Icon</div>
            <div>2. Check if the Version number is 5. If not, convert the code to version 5 by</div>
            <div className='sub-list-container'>
              <div className='sub-list'>a. Create a working copy</div>
              <div className='sub-list'>b. Click on 3 dot above the right</div>
              <div className='sub-list'>c. Then click convert Code to V5 (V3-&gt; V4 -&gt; V5)</div>
            </div>
            <div>3. Copy all in the box below Pine Editor</div>
            <div>4. Can’t merge strategy, only indicator</div>
            <div>5. Tutorial link: <a href='https://www.youtube.com/watch?v=cL2J0R_3LvE' style={{textDecoration: 'underline'}}>https://www.youtube.com/watch?v=cL2J0R_3LvE</a></div>
            <div><strong>GET 5 FREE USES HERE</strong></div>
            <div><a href='https://m.facebook.com/groups/1128766605090440'  style={{textDecoration: 'underline'}}>https://m.facebook.com/groups/1128766605090440</a></div>
        </div>
    </div>
  )
}

export default HeadSection
