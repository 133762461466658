import React, { createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import API from "../ultis/API";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();
export default AuthContext;

export const ContextProvider = ({ children }) => {
  let localAuthToken = localStorage.getItem("authToken")
    ? JSON.parse(localStorage.getItem("authToken"))
    : null;
  
  const [authToken, setAuthToken] = useState(() => localAuthToken);
  const [user, setUser] = useState(() => {
    let localAuthUser = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : null;
    if (localAuthUser) {
      localAuthUser.policy_check = localStorage.getItem("policyCheck") === "true";
    }
    return localAuthUser;
  });

  let navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      let response = await API.post("accounts/token/", {
        username: e.target.username.value,
        password: e.target.password.value,
      });
      let user_data = jwtDecode(response.data.access);
      localStorage.setItem("authToken", JSON.stringify(response.data));
      localStorage.setItem("authUser", JSON.stringify(user_data));
      localStorage.setItem("policyCheck", user_data.policy_check);
      setAuthToken(response.data);
      setUser(user_data);
      toast.success("Login success!", {
        position: "top-right",
      });
      navigate("/");
    } catch (err) {
      logoutUser();
    }
  };

  const logoutUser = () => {
    setUser(null);
    setAuthToken(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("authUser");
    localStorage.removeItem("policyCheck");
    navigate("/login");
  };

  const getUserData = async () => {
    try {
      let resp = await API.get('accounts/detail/');
      if (resp.status === 200) {
        return resp.data;
      } else {
        toast.error("Policy check failed!", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Policy check failed!", {
        position: "top-right",
      });
    }
  };

  const updateToken = async (authToken) => {
    try {
      let user_detail = await getUserData();
      let response = await API.post("accounts/token/refresh/", {
        refresh: authToken.refresh,
      });
      let user_data = jwtDecode(response.data.access);
      user_data["policy_check"] = user_detail["policy_check"];
      localStorage.setItem("authToken", JSON.stringify(response.data));
      localStorage.setItem("authUser", JSON.stringify(user_data));
      localStorage.setItem("policyCheck", user_data.policy_check);
      setUser(user_data);
    } catch (error) {
      console.log("Update token error", error);
    }
  };

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      await API.post(`accounts/register/`, {
        username: e.target.username.value,
        email: e.target.email.value,
        password: e.target.password.value,
      });
      toast.success("Register successfully", {
        position: "top-right",
      });
      navigate("/login");
    } catch (error) {
      toast.error("Register failed, please try again!", {
        position: "top-right",
      });
    }
  };

  const contextData = {
    user: user,
    authToken: authToken,
    loginUser: loginUser,
    logoutUser: logoutUser,
    registerUser: registerUser,
    updateToken: updateToken,
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (authToken) {
        updateToken(authToken);
      }
    }, 1000 * 60 * 4);
    return () => clearInterval(interval);
  }, [authToken]);// eslint-disable-line

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};